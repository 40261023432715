<template>
  <img
    v-if="src"
    :alt="alt"
    class="circle"
    :height="size"
    :src="src"
    :width="size"
  />
  <div
    v-else
    :class="`inline bg-${color} circle`"
    :style="{
      width: `${intSize}px`,
      height: `${intSize}px`,
    }"
  >
    <span
      :class="`text-${color} invert-filter`"
      :style="{
        'font-size': `${fontSize}px`,
      }"
      :title="alt"
    >
      {{ text }}
    </span>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    src?: string
    alt?: string
    size?: number | string
    color?: string
    text?: string
  }>(),
  {
    alt: '',
    src: '',
    size: 20,
    color: '',
    text: '',
  }
)

const intSize = computed(() => {
  return typeof props.size === 'number' ? props.size : parseInt(props.size)
})
const fontSize = computed(() => {
  return intSize.value * 0.6
})
</script>

<style scoped>
.inline {
  display: inline-block;
}
.invert-filter {
  filter: invert(100%) grayscale(100%) contrast(100);
}
.circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-flow: column;
  vertical-align: top;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: width, height;
}
</style>
